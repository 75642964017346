import React from "react";
import { BulkDeleteButton } from "react-admin";

export function DefaultBulkActionButtons(props) {
  return (
    <React.Fragment>
      <BulkDeleteButton {...props} undoable={false} />
    </React.Fragment>
  );
}
