import React, { useState } from "react";
import Calendar from "react-calendar";
import { useInput, useGetList } from "react-admin";
import { useController } from "react-hook-form";
import "react-calendar/dist/Calendar.css";
import { HebrewDateFormatter, JewishDate, JewishCalendar } from "kosher-zmanim";
import { Popover } from "@headlessui/react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Icon from "@mui/material/Icon";
import "../../resources/Sponsor/DatePicker.css";
import { format } from "date-fns";

const HebrewDatepickerInput = ({ source }) => {
  const { field: datepicker } = useController({
    name: "date",
    defaultValue: "",
  });
  const { field: hebrew_year } = useController({
    name: "hebrew_year",
    defaultValue: "",
  });
  const { field: hebrew_month } = useController({
    name: "hebrew_month",
    defaultValue: "",
  });
  const { field: hebrew_date } = useController({
    name: "hebrew_date",
    defaultValue: "",
  });

  const { data } = useGetList("sponsor_dedications", {
    sort: { field: "created_at", order: "DESC" },
  });
  const [date, setDate] = useState(new Date());

  let dedicatedDates = [];
  if (data) {
    dedicatedDates = data
      ?.filter((dedication) => {
        return dedication.date;
      })
      .map((dedication) => {
        let date = new Date(dedication.date);
        date.setHours(0, 0, 0, 0); // Normalize to midnight
        return date;
      });
  }

  const hebrewDateFormatter = new HebrewDateFormatter();
  hebrewDateFormatter.setHebrewFormat(false); // Turn off Hebrew format

  const handleDateChange = (selectedDate, closePopover) => {
    const formattedDate = format(selectedDate, "yyy-MM-dd"); // Adjust the format as needed
    setDate(formattedDate);
    if (selectedDate) {
      let jeweish_date = getJewishDate(selectedDate);
      datepicker.onChange(formattedDate, "");
      if (jeweish_date && jeweish_date.jewishDay) {
        hebrew_date.onChange(jeweish_date.jewishDay, "");
      }
      if (jeweish_date && jeweish_date.jewishMonth) {
        hebrew_month.onChange(jeweish_date.jewishMonth, "");
      }
      if (jeweish_date && jeweish_date.jewishYear) {
        hebrew_year.onChange(jeweish_date.jewishYear, "");
      }
    }
    closePopover();
  };

  const formatDate = (isoString) => {
    if (!isoString) return;
    isoString = isoString.includes("T00:00:00.000Z")
      ? isoString.replace("T00:00:00.000Z", "")
      : isoString;
    const [year, month, day] = isoString.split("-");
    let date = `${month}/${day}/${year}`;
    return date;
  };

  const isSameDay = (date1, date2) => {
    const methods = ["getUTCDate", "getUTCMonth", "getUTCFullYear"];
    return methods.every((method) => date1[method]() === date2[method]());
  };

  const formatShortWeekday = (locale, date) => {
    return ["S", "M", "T", "W", "T", "F", "S"][date.getDay()];
  };

  const tileDisabledFunc = (tile) => {
    if (tile.view !== "month") return false;
    const isTaken =
      dedicatedDates &&
      dedicatedDates.length > 0 &&
      dedicatedDates.some((takenDate) => isSameDay(takenDate, tile.date));
    return isTaken;
  };

  // Function to get the Jewish date for a given Gregorian date
  const getJewishDate = (date) => {
    const jewishDate = new JewishDate(date);
    const jewishDay = jewishDate.getJewishDayOfMonth();
    const jewishMonth = hebrewDateFormatter.formatMonth(jewishDate);
    const jewishYear = jewishDate.getJewishYear();
    let jeweishDateArray = {
      jewishDay: jewishDay && jewishDay,
      jewishMonth: jewishMonth && jewishMonth,
      jewishYear: jewishYear && jewishYear,
    };
    return jeweishDateArray;
  };

  // georgian format
  const getJewishMonthsFromGregorianDate = (date, tishreWithYear = false) => {
    const isTishrei = (jewishDate) => {
      return jewishDate.getJewishMonth() === JewishCalendar.TISHREI;
    };

    const jewishDate = new JewishCalendar(date);

    let jewishMonth1 = hebrewDateFormatter.formatMonth(jewishDate);
    if (isTishrei(jewishDate) && tishreWithYear) {
      jewishMonth1 += ` ${jewishDate.getJewishYear()}`;
    }
    jewishDate.setGregorianDayOfMonth(
      jewishDate.getLastDayOfGregorianMonth(
        jewishDate.getGregorianDayOfMonth(),
      ),
    );
    let jewishMonth2 = hebrewDateFormatter.formatMonth(jewishDate);
    if (isTishrei(jewishDate) && tishreWithYear) {
      jewishMonth2 += ` ${jewishDate.getJewishYear()}`;
    }
    const jewishMonths = [...new Set([jewishMonth1, jewishMonth2])];

    return jewishMonths;
  };

  // Function to render the content of each day tile
  const renderJewishDateTile = ({ date, view }) => {
    const jewishDate = new JewishCalendar(date);
    if (view === "year") {
      const jewishMonths = getJewishMonthsFromGregorianDate(date, true);
      return <div className="">{jewishMonths.join(" / ")}</div>;
    }

    if (view === "month") {
      let formattedDateString = "";
      const isToday = new Date().toDateString() === date.toDateString();
      const isFirstDayOfJewishMonth = jewishDate.getJewishDayOfMonth() === 1;
      if (isToday) {
        formattedDateString = "Today";
      } else if (isFirstDayOfJewishMonth) {
        formattedDateString =
          hebrewDateFormatter.formatRoshChodesh(jewishDate) ||
          hebrewDateFormatter.formatYomTov(jewishDate).slice(0, 7);
      } else if (jewishDate.isYomTov()) {
        formattedDateString = hebrewDateFormatter
          .formatYomTov(jewishDate)
          .slice(0, 7);
      } else {
        const hebrewDay = jewishDate.getJewishDayOfMonth().toString();
        formattedDateString = hebrewDay;
      }
      return (
        <>
          <div className="j-string">{formattedDateString}</div>
          {isFirstDayOfJewishMonth && <hr className="text-blue-line" />}
        </>
      );
    }
  };

  const navigationLabelFunc = ({ date, label, locale, view }) => {
    const jewishDate = new JewishCalendar(date);
    if (view === "year") {
      const jewishYear = jewishDate.getJewishYear();
      const year1 = jewishYear;
      const year2 = jewishYear + 1;

      return (
        <>
          <h4 className="no-margin">{label}</h4>
          <span className="">{`${year1} / ${year2}`}</span>
        </>
      );
    }
    if (view === "month") {
      const jewishMonths = getJewishMonthsFromGregorianDate(date);
      const jewishYear = jewishDate.getJewishYear();

      return (
        <>
          <h4 className="no-margin">{label}</h4>
          <span className="">{`${jewishMonths.join(" / ")} ${jewishYear}`}</span>
        </>
      );
    }
    return label;
  };

  const NextLabel = () => {
    return <Icon component={ArrowForwardIcon} sx={{ width: "24px" }} />;
  };

  const PreviousLabel = () => {
    return <Icon component={ArrowBackIcon} sx={{ width: "24px" }} />;
  };
  return (
    <>
      <Popover className="group w-full grow">
        <Popover.Button
          className="relative w-full no-border"
          style={{ position: "relative" }}
        >
          <span className="jeweish-calendar DatepickerWrapper">
            <Icon
              component={CalendarMonthIcon}
              sx={{
                position: "absolute",
                right: "10px",
                zIndex: "9",
                top: "10px",
              }}
            />
            <input
              type="text"
              value={`${datepicker.value && formatDate(datepicker.value)} - ${hebrew_date && hebrew_date.value} ${hebrew_month && hebrew_month.value}`}
            />
          </span>
        </Popover.Button>
        <Popover.Panel
          className={`absolute z-10 w-full translate-x-0 max-md:left-0 md:w-fit md:-translate-x-1/4`}
        >
          {({ close }) => (
            <div>
              <Calendar
                onChange={(selectedDate) =>
                  handleDateChange(selectedDate, close)
                }
                value={(datepicker.value && new Date(datepicker.value)) || date}
                tileContent={renderJewishDateTile}
                minDate={new Date()}
                maxDate={new Date("2030-12-31")} // Trying to start a Y2K 2.0
                calendarType="gregory"
                navigationLabel={navigationLabelFunc}
                prevLabel={<PreviousLabel />}
                prev2Label={null}
                nextLabel={<NextLabel />}
                next2Label={null}
                minDetail={"year"}
                formatShortWeekday={formatShortWeekday}
                tileDisabled={tileDisabledFunc}
              />
            </div>
          )}
        </Popover.Panel>
      </Popover>
    </>
  );
};
export default HebrewDatepickerInput;
