import React from "react";
import { sanitizeFieldProps } from "../utils";

const style = {
  marginTop: 16,
  marginBottom: 8,
};

export class FieldRow extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props;
    return (
      <div style={style} {...sanitizeFieldProps(rest)}>
        {children}
      </div>
    );
  }
}
