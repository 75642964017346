import React from "react";
import { ReferenceInput, SelectInput } from "react-admin";
import { validation } from "../../components";
// import { filterByName } from "../utils";

export class SpeakerTitleInput extends React.PureComponent {
  render() {
    const {
      label = "Title",
      source = "title",
      reference = "speaker-titles",
      // filterToQuery = filterByName,
      perPage = 25,
      sort = { field: "title", order: "ASC" },
      ...rest
    } = this.props;
    return (
      <ReferenceInput
        label={label}
        source={source}
        reference={reference}
        // filterToQuery={filterToQuery}
        perPage={perPage}
        sort={sort}
        {...rest}
        validate={[validation.required()]}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
    );
  }
}
