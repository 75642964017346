import { Radio } from "lucide-react";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  SelectInput,
  SimpleForm,
  useRedirect,
  TextField,
  TextInput,
  FunctionField,
  useUpdate,
  EditButton,
  useGetList,
} from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import { timezoneOptions } from "../../assets/js/timezones";
import {
  CardListActions,
  ConventionInput,
  // DateTimeInput,
  EditorToolbar,
  parseDateTimeInt,
  SpeakerInput,
} from "../../components";

//date_end is no longer madatory

const validateDateCreated = (values) => {
  const errors = {};
  if (!values.date_start) {
    errors.date_start = ["Date start is required"];
  }
  // if (!values.date_end) {
  //   errors.date_end = ["Date end is required"];
  // }
  // if (values.date_start > values.date_end) {
  //   errors.date_start = ["Date start must be less than Date end!"];
  //   errors.date_end = ["Date end must be greater than Date start!"];
  // }
  return errors;
};
const RenderInput = (record, fetchedEvents, update) => {
  const redirect = useRedirect();
  const [value, setValue] = useState(record.row_order);
  const [loader, SetLoader] = useState(false);

  const ChangeOrder = (value) => {
    if (!record || !value) return null;
    if (record.row_order != value) {
      // const fetchedEvents = data;
      const previousrecord = record;
      const previousRowOrder = record.row_order;
      const newRowOrder = parseInt(value);
      SetLoader(true);
      update(
        "manage-live-events",
        {
          id: record.id,
          data: { row_order: newRowOrder },
          previousData: previousrecord,
        },
        {
          onSuccess: () => {
            console.log("successs");
          },
          onFailure: (error) => {
            console.log("failure");
          },
        },
      );

      let sortnumber = newRowOrder + 1;
      if (newRowOrder < previousRowOrder) {
        fetchedEvents.forEach((event) => {
          if (
            event.row_order >= newRowOrder &&
            newRowOrder <= event.row_order &&
            event.id != record.id
          ) {
            const diff = { row_order: sortnumber };
            update(
              "manage-live-events",
              { id: event.id, data: diff, previousData: event },
              {
                onSuccess: () => {
                  SetLoader(false);
                  window.location.reload();
                  // redirect("list", "manage-live-events");
                  console.log("successs");
                },
                onFailure: (error) => {
                  console.log("failure");
                },
              },
            );
            sortnumber++;
          }
        });
      } else if (newRowOrder > previousRowOrder) {
        let sortnumber = previousRowOrder;
        fetchedEvents.forEach((event) => {
          if (
            event.row_order > previousRowOrder &&
            event.id != record.id &&
            event.row_order <= newRowOrder
          ) {
            const diff = { row_order: sortnumber };
            update(
              "manage-live-events",
              { id: event.id, data: diff, previousData: event },
              {
                onSuccess: () => {
                  SetLoader(false);
                  window.location.reload();
                  // redirect("list", "manage-live-events");
                  console.log("successs");
                },
                onFailure: (error) => {
                  console.log("failure");
                },
              },
            );
            sortnumber++;
          }
        });
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {loader ? (
        <Icon
          sx={{
            width: "10px",
            height: "10px",
            position: "absolute",
            right: "-20px",
            zIndex: "9",
            top: "0px",
          }}
          component={CircularProgress}
        />
      ) : (
        ""
      )}

      <input
        style={{
          width: "60px",
          height: "40px",
          textAlign: "center",
          border: "1px solid #0000001f",
        }}
        name="row_order"
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => ChangeOrder(value)}
        value={value}
      ></input>
    </div>
  );
};

const EventSave = () => {
  const redirect = useRedirect();
  const [update, { isPending }] = useUpdate();
  const { data, total, error } = useGetList("manage-live-events", {
    sort: { field: "row_order", order: "ASC" },
  });
  if (!data) return null;
  const fetchedEvents = data;
  const evetSave = (data) => {
    const previousrecord = fetchedEvents.filter((e) => e.id == data.id);
    if (!previousrecord || previousrecord.length <= 0) return null;
    // save the current record
    update(
      "manage-live-events",
      { id: data.id, data: data, previousData: previousrecord[0] },
      {
        onSuccess: () => {
          console.log("successs");
        },
        onFailure: (error) => {
          console.log("failure");
        },
      },
    );
    const previousRowOrder = previousrecord[0].row_order;
    const newRowOrder = parseInt(data.row_order);
    if (previousRowOrder == newRowOrder) return;
    let sortnumber = newRowOrder + 1;
    if (newRowOrder < previousRowOrder) {
      fetchedEvents.forEach((event) => {
        if (event.row_order >= newRowOrder && event.id != data.id) {
          const diff = { row_order: sortnumber };
          update(
            "manage-live-events",
            { id: event.id, data: diff, previousData: event },
            {
              onSuccess: () => {
                console.log("successs");
              },
              onFailure: (error) => {
                console.log("failure");
              },
            },
          );
          sortnumber++;
        }
      });
    } else if (newRowOrder > previousRowOrder) {
      let sortnumber = previousRowOrder;
      fetchedEvents.forEach((event) => {
        if (
          event.row_order > previousRowOrder &&
          event.id != data.id &&
          event.row_order <= newRowOrder
        ) {
          const diff = { row_order: sortnumber };
          console.log(
            "currento order",
            event.row_order,
            "udpating to",
            sortnumber,
          );
          update(
            "manage-live-events",
            { id: event.id, data: diff, previousData: event },
            {
              onSuccess: () => {
                console.log("successs");
              },
              onFailure: (error) => {
                console.log("failure");
              },
            },
          );
          sortnumber++;
        }
      });
    }
    redirect("list", "manage-live-events");
  };
  return (
    <SimpleForm
      // redirect={redirect}
      toolbar={<EditorToolbar />}
      // validate={validateDateCreated}
      onSubmit={evetSave}
    >
      <BooleanInput source="display_active" label="Display" />
      <TextInput source="event_title" label="Title" fullWidth />
      <TextInput source="url" label="Event URL" fullWidth />
      <BooleanInput source="ladies" />
      <TextInput source="event_picture" label="Event Picture" />
      <ImageInput
        source="FILE_upload"
        label="Upload Event Picture"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <DateTimeInput
        source="date_start"
        label="Start"
        parse={parseDateTimeInt}
        required
      />
      <DateTimeInput
        source="date_end"
        label="End"
        parse={parseDateTimeInt}
        helperText="End time is automatically 1 hour after the start time unless otherwise specified."
      />
      <ConventionInput source="convention_id" allowEmpty />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.convention_id > 0 && (
              <>
                <TextInput source="extension" />

                <SelectInput
                  source="time_zone"
                  label="Timezone"
                  choices={timezoneOptions}
                />
                <BooleanInput source="is_keynote" />
                <NumberInput
                  source="lecture_id"
                  allowEmpty
                  label="Lecture ID"
                />
                <TextInput
                  source="speaker_list"
                  allowEmpty
                  label="Speaker List (used by conventions)"
                />
              </>
            )
          );
        }}
      </FormDataConsumer>
      <TextInput source="row_order" label="Row Order" />
      {/* 
      <TextInput source="ref_post_id" label="Post ID" />
      <TextInput source="recurring_pattern" label="Recurring Pattern" />
      <BooleanInput source="recurring" label="Recurring" />
      <LanguageInput allowEmpty />
      <LocationInput allowEmpty />
      <HostInput allowEmpty />
      <OrganizationInput allowEmpty />
      <SpeakerInput allowEmpty />
      <CategoryInput allowEmpty />
      <SubCategoryInput allowEmpty /> 
      */}
    </SimpleForm>
  );
};

function renderEditForm({ redirect }) {
  return (
    <SimpleForm
      // redirect={redirect}
      toolbar={<EditorToolbar />}
      // validate={validateDateCreated}
    >
      <BooleanInput source="display_active" label="Display" />
      <TextInput source="event_title" label="Title" fullWidth />
      <TextInput source="url" label="Event URL" fullWidth />
      <BooleanInput source="ladies" />
      <TextInput source="event_picture" label="Event Picture" />
      <ImageInput
        source="FILE_upload"
        label="Upload Event Picture"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <DateTimeInput
        source="date_start"
        label="Start"
        parse={parseDateTimeInt}
        required
      />
      <FormDataConsumer>
        {() => {
          const dateStart = useWatch({ name: "date_start" });
          const { setValue } = useFormContext();

          useEffect(() => {
            // Add 3600 seconds (1 hour) to the date_start timestamp
            if (dateStart && !Number.isNaN(dateStart)) {
              const newEndDate = dateStart + 3600 * 1000;
              setValue("date_end", newEndDate);
            }
          }, [dateStart, setValue]);

          return (
            <DateTimeInput
              source="date_end"
              label="End"
              parse={parseDateTimeInt}
              helperText="End time is automatically 1 hour after the start time unless otherwise specified."
            />
          );
        }}
      </FormDataConsumer>
      <ConventionInput source="convention_id" allowEmpty />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            formData.convention_id > 0 && (
              <>
                <TextInput source="extension" />

                <SelectInput
                  source="time_zone"
                  label="Timezone"
                  choices={timezoneOptions}
                />
                <BooleanInput source="is_keynote" />
                <NumberInput
                  source="lecture_id"
                  allowEmpty
                  label="Lecture ID"
                />
                <TextInput
                  source="speaker_list"
                  allowEmpty
                  label="Speaker List (used by conventions)"
                />
              </>
            )
          );
        }}
      </FormDataConsumer>
      {/* <TextInput source="row_order" label="Row Order" /> */}
      {/* 
      <TextInput source="ref_post_id" label="Post ID" />
      <TextInput source="recurring_pattern" label="Recurring Pattern" />
      <BooleanInput source="recurring" label="Recurring" />
      <LanguageInput allowEmpty />
      <LocationInput allowEmpty />
      <HostInput allowEmpty />
      <OrganizationInput allowEmpty />
      <SpeakerInput allowEmpty />
      <CategoryInput allowEmpty />
      <SubCategoryInput allowEmpty /> 
      */}
    </SimpleForm>
  );
}

const DateEnd = () => {
  const dateStart = useWatch({ name: "date_start" });
  const { setValue } = useFormContext();

  useEffect(() => {
    // Add 3600 seconds (1 hour) to the date_start timestamp
    if (dateStart) {
      const newEndDate = dateStart + 3600 * 1000;
      // Only update if date_end is not already set or if it's less than the calculated end date
      if (!dateStart || dateStart < newEndDate) {
        setValue("date_end", newEndDate, { shouldTouch: true });
      }
    }
  }, [dateStart, setValue]);

  return (
    <DateTimeInput
      source="date_end"
      label="End"
      parse={parseDateTimeInt}
      helperText="End time is automatically 1 hour after the start time unless otherwise specified."
    />
  );
};

function EditView(props) {
  return (
    <Edit {...props} undoable={false}>
      {renderEditForm({ redirect: "list" })}
    </Edit>
  );
}

function CreateView(props) {
  return <Create {...props}>{renderEditForm({ redirect: "edit" })}</Create>;
}

const ListView = (props) => {
  const { data, total, error } = useGetList("manage-live-events", {
    sort: { field: "row_order", order: "ASC" },
  });
  const [update, { isPending }] = useUpdate();

  return (
    <List
      {...props}
      actions={<CardListActions />}
      exporter={true}
      filters={[
        <TextInput label="Title" source="event_title" alwaysOn />,
        <SpeakerInput
          label="Speaker"
          source="speaker"
          reference="speakers"
          alwaysOn
        />,
      ]}
      perPage={25}
      sort={{ field: "row_order", order: "ASC" }}
      title="Live Events"
      // queryOptions={{ refetchInterval: 5000 }}
    >
      <Datagrid>
        <FunctionField
          label="Row Order"
          source="row_order"
          render={(record) => RenderInput(record, data, update)}
        />
        {/* <TextField
          source="row_order"
          label="Row Order"
          onClick={console.log("clicked")}
        /> */}
        <ImageField source="pic_url" label="Picture" />
        <TextField source="event_title" label="Title" />
        <TextField source="speaker_name" label="By" style={{ minWidth: 180 }} />
        <BooleanField source="display_active" label="Active" />
        <DateField
          source="date_start"
          showTime
          label="Start"
          style={{ minWidth: 160 }}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

// function ListView(props) {
//   return (
//     <List
//       {...props}
//       actions={<CardListActions />}
//       exporter={true}
//       filters={[
//         <TextInput label="Title" source="event_title" alwaysOn />,
//         <SpeakerInput
//           label="Speaker"
//           source="speaker"
//           reference="speakers"
//           alwaysOn
//         />,
//       ]}
//       perPage={25}
//       sort={{ field: "row_order", order: "ASC" }}
//       title="Live Events"
//     >
//       <Datagrid>
//         <FunctionField
//           label="Row Order"
//           render={(record) =>
//             RenderInput(record)
//           }
//         />
//         {/* <TextField
//           source="row_order"
//           label="Row Order"
//           onClick={console.log("clicked")}
//         /> */}
//         <ImageField source="pic_url" label="Picture" />
//         <TextField source="event_title" label="Title" />
//         <TextField source="speaker_name" label="By" style={{ minWidth: 180 }} />
//         <BooleanField source="display_active" label="Active" />
//         <DateField
//           source="date_start"
//           showTime
//           label="Start"
//           style={{ minWidth: 160 }}
//         />
//         <EditButton />
//       </Datagrid>
//     </List>
//   );
// }

export const LiveEvents = {
  category: "manage",
  name: "manage-live-events",
  edit: EditView,
  icon: Radio,
  list: ListView,
  create: CreateView,
  options: { label: "Live Events" },
};
